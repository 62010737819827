import React, { useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faChevronLeft, faFileUpload, faSpinner, faSquare } from '@fortawesome/pro-regular-svg-icons';
import { DateTime } from 'luxon';
import { faCalendarEdit } from '@fortawesome/pro-regular-svg-icons';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import Button from '@ssg/common/Components/Button';
import { notNull } from '@ssg/common/Helpers/notNull';
import { fileListToFileInputList, getObjectId } from '../../../helper';
import InvoiceStepView from './InvoiceStepView';
import InvoiceStepCreate from './InvoiceStepCreate';
import InvoiceStepEdit from './InvoiceStepEdit';
import InvoiceStepValidation from './InvoiceStepValidation';
import InvocieStepAttachments from './InvoiceStepAttachments';
import InvoiceStepSummary from './InvoiceStepSummary';
import {
	ApproveInvoice,
	ApproveInvoiceVariables,
	CreateInvoice,
	CreateInvoiceVariables,
	DeleteInvoice,
	DeleteInvoiceVariables,
	DraftInvoiceLineFragment,
	DraftInvoiceLineInputType,
	GetInvoices,
	GetInvoices_invoices,
	GetInvoicesVariables,
	InvoiceCategory,
	InvoiceStatus,
	JobTasks,
	JobTasksVariables,
	JobTasks_jobTasks,
	OpenInvoiceForEditing,
	OpenInvoiceForEditingVariables,
	RequestInvoiceApproval,
	RequestInvoiceApprovalVariables,
	SetInvoiceReasonCodes,
	SetInvoiceReasonCodesVariables,
	SuggestInvoiceLines,
	SuggestInvoiceLinesVariables,
	UpdateInvoice,
	UpdateInvoiceVariables,
	ValidateInvoice,
	ValidateInvoiceVariables,
	ValidationRuleBypassInput,
	GetDraftInvoiceLineTypes_draftInvoiceLineTypes,
	AllAssortmentsVariables,
	AllAssortments,
	AllAssortments_allAssortments,
	UploadInvoiceFiles,
	UploadInvoiceFilesVariables,
	DeleteInvoiceFile,
	DeleteInvoiceFileVariables,
	GetInvoiceAttachmentsVariables,
	GetInvoiceAttachments,
	OverrideInvoiceDates,
	OverrideInvoiceDatesVariables,
	GetSamplePriceForInvoiceLine_samplePriceForInvoiceLine,
	UpdateScreeningInvoice,
	UpdateScreeningInvoiceVariables,
	GetScreenings,
	GetScreeningsVariables,
} from '../../../GraphQL';
import DateRangePicker from '../../Planner/DateRangePicker';
import Loading from '@ssg/common/Components/Loading';
import Datepicker from '@ssg/common/Components/Datepicker';
import ProgressIcon, { ProgressColor } from '../../../Components/ProgressIcon';
import { GetDebitor_debitor } from '../../../GraphQL';
import { useFlag } from '@unleash/proxy-client-react';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';
import { DEFAULT_ENVFEE_NAME, shouldCalculateEnvFeeForCategory, shouldCalculateEnvironmentalFeeForDebitor } from '../../../environmentalFeeHelper';
import UserContext from '../../../UserContext';

const CREATE_INVOICE = loader('src/GraphQL/Invoices/CreateInvoice.gql');
const APPROVE_INVOICE = loader('src/GraphQL/Invoices/ApproveInvoice.gql');
const DELETE_INVOICE = loader('src/GraphQL/Invoices/DeleteInvoice.gql');
const INVOICE_VALIDATION = loader('src/GraphQL/Invoices/ValidateInvoice.gql');
const SUGGEST_INVOICE_LINES = loader('src/GraphQL/Invoices/SuggestInvoiceLines.gql');
const REQUEST_INVOICE_APPROVAL = loader('src/GraphQL/Invoices/RequestInvoiceApproval.gql');
const SET_INVOICE_REASON_CODES = loader('src/GraphQL/Invoices/SetInvoiceReasonCodes.gql');
const UPDATE_INVOICE = loader('src/GraphQL/Invoices/UpdateInvoice.gql');
const UPDATE_SCREENING_INVOICE = loader('src/GraphQL/Invoices/UpdateScreeningInvoice.gql');
const GET_INVOICES = loader('src/GraphQL/Invoices/GetInvoices.gql');
const OPEN_INVOICE_FOR_EDITING = loader('src/GraphQL/Invoices/OpenInvoiceForEditing.gql');
const GET_JOB_TASKS = loader('src/GraphQL/Offer/GetJobTask.gql');
const GET_ALL_ASSORTMENTS = loader('src/GraphQL/Invoices/GetAllAssortments.gql');
const UPLOAD_INVOICE_FILES = loader('src/GraphQL/Files/UploadInvoiceFiles.gql');
const DELETE_INVOICE_FILE = loader('src/GraphQL/Files/DeleteInvoiceFile.gql');
const GET_INVOICE_ATTACHMENTS = loader('src/GraphQL/Invoices/GetInvoiceAttachments.gql');
const OVERRIDE_INVOICE_DATES = loader('src/GraphQL/Invoices/OverrideInvoiceDates.gql');
const GET_SCREENINGS = loader('src/GraphQL/Screening/GetScreenings.gql');

function createBlankInvoiceLine(createdBy: string, caseNo: string, documentNo: string, planningDate: string, lastIndex: number): EditableInvoiceLine {
	return {
		renderKey: getObjectId(),
		systemId: null,
		sortIndex: lastIndex + 1, // C# int max value
		caseNo: caseNo,
		documentNo: documentNo,
		taskNo: '',
		type: '',
		no: '',
		description: '',
		quantity: 1,
		newUnitPrice: 0,
		planningDate: planningDate,
		workTypeCode: '',
		createdBy: createdBy,
	};
}

function createEnvFeeLine(emptyLine: EditableInvoiceLine, shouldCreate: boolean): EditableInvoiceLine[] {

	if (!shouldCreate) return [];
	const newLine = emptyLine;

	emptyLine.no = DEFAULT_ENVFEE_NAME;
	emptyLine.type = 'Resource';
	emptyLine.description = DEFAULT_ENVFEE_NAME;

	return [newLine];
}

export function toEditableInvoiceLine(line: InvoiceLine): EditableInvoiceLine {
	return {
		renderKey: getObjectId(),
		amount: line.lineAmount ?? undefined,
		cost: line.unitCost ?? undefined,
		systemId: line.systemId,
		sortIndex: line.sortIndex,
		caseNo: line.caseNo,
		documentNo: line.documentNo,
		taskNo: line.erpReferenceTask,
		type: line.type,
		attachDocument: line.attachDocument,
		allowAttachDocument: line.allowAttachDocument,
		no: line.no,
		description: line.description,
		quantity: line.quantity ?? 0,
		newUnitPrice: line.unitPrice ?? 0,
		planningDate: line.planningDate,
		workTypeCode: line.workTypeCode,
		screening: line.screeningTitle || undefined,
		screeningLineId: line.screeningLineId || undefined,
		screeningLinePosition: line.screeningLinePosition || undefined,
		createdBy: line.createdBy,
	};
}

export function lineTypeHasAssortment(line: EditableInvoiceLine): boolean {
	return line.type === 'Resource' || line.type === 'Item'; // TODO: Make dynamic
}

export function lineTypeIsText(line: EditableInvoiceLine): boolean {
	return line.type === 'Text'; // TODO: Make dynamic
}

export function lineTypeIsGLAccount(line: EditableInvoiceLine): boolean {
	return line.type === 'G/L Account';
}

export function lineIsValid(line: EditableInvoiceLine): boolean {
	if (line.no === DEFAULT_ENVFEE_NAME) return true;
	if (lineTypeIsText(line) && line.description?.length > 0) {
		return true;
	}

	if (lineTypeHasAssortment(line) && line.no && line.no !== '' && line.quantity && line.newUnitPrice) {
		return true;
	}

	if (lineTypeIsGLAccount(line) && line.quantity && line.newUnitPrice) {
		return true;
	}

	return false;
}

export type EditableInvoiceLine = DraftInvoiceLineInputType & {
	renderKey: string;
	amount?: number;
	cost?: number;
	screening?: string;
	screeningLineId?: string;
	screeningLinePosition?: string;
	allowAttachDocument?: boolean;
	unitPrice?: number;
	excludeInEnvFee?: boolean;
};

// Hide Comments from the user as they are for internal purposes
function filterInvoiceLines(lines: EditableInvoiceLine[]): EditableInvoiceLine[] {
	return lines.filter(line => lineTypeHasAssortment(line) || lineTypeIsText(line) || lineTypeIsGLAccount(line));
}

export type InvoiceDraft = GetInvoices_invoices;
export type InvoiceLine = DraftInvoiceLineFragment;
export type InvoiceJobTask = JobTasks_jobTasks;
export type InvoiceLineType = GetDraftInvoiceLineTypes_draftInvoiceLineTypes;
export type InvoiceAssortment = AllAssortments_allAssortments;
export type InvoiceSamplePrice = {
	sample: GetSamplePriceForInvoiceLine_samplePriceForInvoiceLine;
	standardUnitPrice: number;
};

enum InvoiceStep {
	Loading,
	View,
	Create,
	Edit,
	OverrideDates,
	Validation,
	Attachments,
	Summary,
}

enum InvoiceBtnStep {
	CheckFields,
	Validate,
}

interface Props {
	caseId: string;
	caseNo: string;
	caseCreated: DateTime;
	track: number;
	debitor: GetDebitor_debitor;
	assortmentCode: string;
	visible: boolean;
	close(): void;
}

const InvoiceModal: React.FC<Props> = ({ caseId, caseNo, caseCreated, track, assortmentCode, visible, debitor, close }): React.ReactElement => {
	const { t } = useTranslation();
	const enabledcheckBcValueInSlutFaktura = useFlag(FeatureFlagEnums.CHECK_BC_VALUE_IN_SLUTFAKTURA);

	const userContext = React.useContext(UserContext);
	const userName = userContext.user?.name ?? '';

	// Setup state
	const [step, setStep] = React.useState<InvoiceStep>(InvoiceStep.Loading);
	const [lines, setLines] = React.useState<EditableInvoiceLine[]>([]);
	const [description, setDescription] = React.useState('');
	const [type, setType] = React.useState<InvoiceCategory>(track === 1 ? InvoiceCategory.TRACK_ONE : InvoiceCategory.FINAL);
	const [bypassReasons, setBypassReasons] = React.useState<ValidationRuleBypassInput[]>([]);
	const invoiceERPReference = React.useRef<string>();
	const [editRenderKey, setEditRenderKey] = React.useState(Date.now());
	//const [totalEnvFee, setTotalEnvFee] = React.useState<number>(0);
	const [invoiceBtnStep, setInvoiceBtnStep] = React.useState<InvoiceBtnStep>(InvoiceBtnStep.CheckFields);
	const [highlightBcDifference, setHighlightBcDifference] = React.useState(false);


	const shouldCalculateEnvFeeForDebitor = shouldCalculateEnvironmentalFeeForDebitor(debitor.environmentFeePercentage);

	// Get the invoices
	const {
		data: existingInvoicesData,
		loading: loadingExistingInvoices,
		refetch: refetchInvoices,
		error: errorInvoices,
	} = useQuery<GetInvoices, GetInvoicesVariables>(GET_INVOICES, {
		variables: { caseId },
		fetchPolicy: 'no-cache',
	});

	const somelinesAreInvalid = React.useMemo(() => (type !== InvoiceCategory.SCREENING ? lines.some(line => !lineIsValid(line)) : lines.length === 0), [lines, type]);
	const lastLineIsValid = React.useMemo(() => (lines.length > 0 ? lineIsValid(lines[lines.length - 1]) : true), [lines]);

	// Extract the first invoice that is not approved (There should be 0 or 1)
	const existingInvoice = React.useMemo(() => existingInvoicesData?.invoices.find(invoice => invoice.status !== InvoiceStatus.APPROVED), [existingInvoicesData?.invoices]);

	const [invoicePostingDate, setInvoicePostingDate] = useState<string>(caseCreated.toString().split('T')[0]);
	const [invoiceStartDate, setInvoiceStartDate] = useState<DateTime>(DateTime.fromISO('0001-01-01'));
	const [invoiceEndDate, setInvoiceEndDate] = useState<DateTime>(DateTime.fromISO('9999-12-31'));

	// Navigate to the correct step when the existing invoice has loaded
	React.useEffect(() => {
		if (loadingExistingInvoices) return;
		if (step !== InvoiceStep.Loading) return;

		if (typeof existingInvoice !== 'undefined') {
			setStep(InvoiceStep.View);
			setType(existingInvoice.category);
			setBypassReasons(
				existingInvoice.validationRuleBypasses.map(({ rule, reasonCode, comment }) => ({
					rule,
					reasonCode,
					comment,
				})),
			);
			//setInvoicePostingDate(existingInvoice.invoicePostingDate);
			setInvoiceStartDate(DateTime.fromFormat(existingInvoice.invoiceStartDate, 'yyyy-MM-dd'));
			setInvoiceEndDate(DateTime.fromFormat(existingInvoice.invoiceEndDate, 'yyyy-MM-dd'));
		} else {
			setStep(InvoiceStep.Create);
		}
	}, [loadingExistingInvoices, existingInvoice, step]);

	// Get job tasks
	const { data: jobTaskData } = useQuery<JobTasks, JobTasksVariables>(GET_JOB_TASKS, { variables: { erpReferenceNo: caseNo } });
	const jobTasks = jobTaskData?.jobTasks?.filter(notNull) ?? [];

	// TODO: What is best practice here? The API returns all types however we only care about 3 (not 6). It seems easier to hardcode.
	const invoiceLineTypes = [
		{ id: 0, name: 'Item' },
		{ id: 1, name: 'Resource' },
		{ id: 2, name: 'Text' },
	];

	const { data: assortmentData } = useQuery<AllAssortments, AllAssortmentsVariables>(GET_ALL_ASSORTMENTS, {
		variables: {
			assortmentCode: assortmentCode,
		},
	});
	const invoiceAssortments = assortmentData?.allAssortments?.filter(notNull) ?? [];

	// Helper for suggest lines mutation call
	const [includePreviousLines, setIncludePreviousLines] = React.useState(false);

	// Setup mutations
	const [approveInvoice] = useMutation<ApproveInvoice, ApproveInvoiceVariables>(APPROVE_INVOICE);
	const [createDraftInvoice, { data: invoiceDraftData, loading: creatingInvoiceDraft }] = useMutation<CreateInvoice, CreateInvoiceVariables>(CREATE_INVOICE);
	const [requestInvoiceApproval, { loading: requestingInvoiceApproval }] = useMutation<RequestInvoiceApproval, RequestInvoiceApprovalVariables>(REQUEST_INVOICE_APPROVAL);
	const [updateInvoice, { data: updateInvoiceData, loading: updatingInvoice }] = useMutation<UpdateInvoice, UpdateInvoiceVariables>(UPDATE_INVOICE);
	const [updateScreeningInvoice, { /*data: updateScreeningInvoiceData,*/ loading: updatingScreeningInvoice }] = useMutation<UpdateScreeningInvoice, UpdateScreeningInvoiceVariables>(
		UPDATE_SCREENING_INVOICE,
	);
	const [setInvoiceReasonCodes, { loading: submittingBypassReasons }] = useMutation<SetInvoiceReasonCodes, SetInvoiceReasonCodesVariables>(SET_INVOICE_REASON_CODES);
	const [validateInvoice, { loading: validatingInvoice, data: invoiceValidationData }] = useMutation<ValidateInvoice, ValidateInvoiceVariables>(INVOICE_VALIDATION);
	const [deleteInvoice, { loading: deletingInvoice }] = useMutation<DeleteInvoice, DeleteInvoiceVariables>(DELETE_INVOICE);
	const [openInvoiceForEditing, { loading: openingInvoiceForEditing }] = useMutation<OpenInvoiceForEditing, OpenInvoiceForEditingVariables>(OPEN_INVOICE_FOR_EDITING);
	const [suggestInvoiceLines, { loading: suggestingInvoiceLines }] = useMutation<SuggestInvoiceLines, SuggestInvoiceLinesVariables>(SUGGEST_INVOICE_LINES);
	const [uploadInvoiceFiles, { loading: uploadingAttachments }] = useMutation<UploadInvoiceFiles, UploadInvoiceFilesVariables>(UPLOAD_INVOICE_FILES);
	const [deleteInvoiceFile, { loading: deletingAttachment }] = useMutation<DeleteInvoiceFile, DeleteInvoiceFileVariables>(DELETE_INVOICE_FILE);
	const [getInvoiceAttachments, invoiceAttachmentsResult] = useLazyQuery<GetInvoiceAttachments, GetInvoiceAttachmentsVariables>(GET_INVOICE_ATTACHMENTS, {
		notifyOnNetworkStatusChange: true,
	});

	React.useEffect(() => {
		if (step !== InvoiceStep.Attachments) return;
		if (invoiceERPReference.current == null) return;
		if (invoiceAttachmentsResult.called) return;

		getInvoiceAttachments({
			variables: { caseId, invoiceNo: invoiceERPReference.current },
		});
	}, [step, invoiceAttachmentsResult, getInvoiceAttachments, caseId]);

	const [overrideInvoiceDates, { loading: overridingInvoiceDates }] = useMutation<OverrideInvoiceDates, OverrideInvoiceDatesVariables>(OVERRIDE_INVOICE_DATES);

	// Make sure there is selected a reason code for every bypassable validation error
	const validationOk = React.useMemo(() => {
		if (invoiceValidationData == null) return false;

		return invoiceValidationData.validateInvoice.every(check => check.value.valid || (check.value.bypassAllowed && bypassReasons.some(br => br.rule === check.key && br.reasonCode.length !== 0)));
	}, [bypassReasons, invoiceValidationData]);

	// Refetch screenings
	const { data: screeningData, refetch } = useQuery<GetScreenings, GetScreeningsVariables>(GET_SCREENINGS, {
		context: { debatch: true },
		variables: { id: caseId },
		notifyOnNetworkStatusChange: true,
		fetchPolicy: 'cache-only',
		nextFetchPolicy: 'network-only',
	});

	const screeningLines = React.useMemo(() => screeningData?.case.screenings.flatMap(s => s.lines.map(l => ({ label: l.uom, value: l.erpReferenceId }))) ?? [], [screeningData?.case.screenings]);

	const {
		bodyElement,
		footerElement,
		size = ModalSize.LARGE,
	} = (() => {
		if (errorInvoices) {
			return {
				bodyElement: <p>Error</p>,
				footerElement: undefined,
			};
		}

		switch (step) {
			case InvoiceStep.Loading:
				return {
					bodyElement: (
						<div className="h-8">
							<FontAwesomeIcon icon={faSpinner} className="text-blue animate-spin" size="lg" />
						</div>
					),
					footerElement: undefined,
				};

			case InvoiceStep.View:
				// This will only happen when transitioning from VIEW to LOADING after deleting an invoice
				if (existingInvoice == null) return { bodyElement: <></>, footerElement: undefined };

				return {
					bodyElement: (
						<>
							{loadingExistingInvoices || (deletingInvoice && <Loading />)}
							<InvoiceStepView invoiceDraft={existingInvoice} debitor={debitor} category={type} assortments={invoiceAssortments} screeningLines={screeningLines} />
						</>
					),
					footerElement: (
						<div className="flex w-full justify-between">
							{existingInvoice.status === InvoiceStatus.AWAITING_APPROVAL && (
								<Button
									primary
									text="case.invoice.openForEditing"
									disabled={openingInvoiceForEditing}
									onClick={async () => {
										await openInvoiceForEditing({
											variables: {
												caseId: caseId,
												invoiceERPReference: existingInvoice.erpReferenceNo,
											},
										});

										await refetchInvoices();
									}}
								/>
							)}

							{existingInvoice.status !== InvoiceStatus.AWAITING_APPROVAL && (
								<Button
									primary
									text="common.edit"
									onClick={() => {
										// Set the invoice reference and save the new state
										invoiceERPReference.current = existingInvoice.erpReferenceNo;

										let linesFromInvoice = existingInvoice.lines.map(toEditableInvoiceLine).map(nl => {
											const jobTaskNo = nl.taskNo;
											const foundJobTask = jobTasks.find(j => j.jobTaskNo === jobTaskNo);
											if (foundJobTask) {
												nl.excludeInEnvFee = foundJobTask.excludeInEnvFee ?? false;
											}
											nl.createdBy = nl.createdBy.length > 0 ? nl.createdBy : userName;
											return nl;
										});
										if (type !== InvoiceCategory.SCREENING) {
											linesFromInvoice =
												existingInvoice.lines.length > 0
													? [...filterInvoiceLines(linesFromInvoice), ...createEnvFeeLine(createBlankInvoiceLine(userName, caseNo, existingInvoice.erpReferenceNo, invoicePostingDate, 99999), (shouldCalculateEnvFeeForDebitor && shouldCalculateEnvFeeForCategory(type) && (linesFromInvoice.filter(l => l.no === DEFAULT_ENVFEE_NAME).length === 0)))]
													: [createBlankInvoiceLine(userName, caseNo, existingInvoice.erpReferenceNo, invoicePostingDate, 0), ...createEnvFeeLine(createBlankInvoiceLine(userName, caseNo, existingInvoice.erpReferenceNo, invoicePostingDate, 99999), (shouldCalculateEnvFeeForDebitor && shouldCalculateEnvFeeForCategory(type)))];
										}
										linesFromInvoice.sort((a, b) => a.sortIndex - b.sortIndex);

										setLines(linesFromInvoice);
										setDescription(existingInvoice.invoiceDescription ?? '');
										setStep(InvoiceStep.Edit);
									}}
								/>
							)}

							<Button
								danger
								text="common.delete"
								disabled={deletingInvoice}
								onClick={async () => {
									if (window.confirm(t('case.invoice.deleteConfirm'))) {
										setStep(InvoiceStep.Loading);

										await deleteInvoice({
											variables: {
												caseId,
												invoiceERPReference: existingInvoice.erpReferenceNo,
											},
										});

										await refetchInvoices();

										setStep(InvoiceStep.Loading);
									}
								}}
							/>
						</div>
					),
				};

			case InvoiceStep.Create:
				return {
					bodyElement: (
						<>
							{creatingInvoiceDraft && <Loading />}
							<InvoiceStepCreate isTrackOne={track === 1} setType={setType} />
						</>
					),
					footerElement: (
						<Button
							primary
							text="common.create"
							onClick={async () => {
								const result = await createDraftInvoice({
									variables: {
										caseId,
										category: type,
									},
								});

								// Extract invoice from the result
								const invoice = result.data?.createInvoice;
								if (invoice == null) return;

								refetchInvoices();

								// Set the invoice reference and save the new state
								invoiceERPReference.current = invoice.erpReferenceNo;

								const invoiceLines =
									type !== InvoiceCategory.SCREENING ? [createBlankInvoiceLine(userName, caseNo, invoice.erpReferenceNo, invoicePostingDate, 0), ...createEnvFeeLine(createBlankInvoiceLine(userName, caseNo, invoice.erpReferenceNo, invoicePostingDate, 999999), (shouldCalculateEnvFeeForDebitor && shouldCalculateEnvFeeForCategory(type)))] : invoice.lines.map(toEditableInvoiceLine);
								invoiceLines.sort((a, b) => a.sortIndex - b.sortIndex);

								setLines(invoiceLines);
								setStep(InvoiceStep.Edit);
							}}
							disabled={creatingInvoiceDraft}
						/>
					),
				};

			case InvoiceStep.Edit:
				return {
					size: ModalSize.XLARGE,
					bodyElement: (
						<>
							{(suggestingInvoiceLines || updatingInvoice || validatingInvoice) && <Loading />}
							<InvoiceStepEdit
								key={editRenderKey}
								caseId={caseId}
								invoiceERPReferenceNo={existingInvoice?.erpReferenceNo ?? ''}
								lines={lines.filter(line => line.type !== 'Comment')}
								existingLines={existingInvoice?.lines.filter(line => line.type !== 'Comment') ?? []}
								setLines={setLines}
								jobTasks={jobTasks}
								lineTypes={invoiceLineTypes}
								assortments={invoiceAssortments}
								screeningLines={screeningLines}
								locked={type === InvoiceCategory.SCREENING}
								description={description}
								setDescription={setDescription}
								isScreening={type === InvoiceCategory.SCREENING}
								isFinal={type === InvoiceCategory.FINAL}
								debitor={debitor}
								category={existingInvoice?.category}
								highlightBcDifference={highlightBcDifference}
							/>
						</>
					),
					footerElement: (
						<>
							<div className="flex w-full flex-row justify-between">
								<div className="flex flex-row gap-2">
									<Button
										primary
										text={enabledcheckBcValueInSlutFaktura ? (invoiceBtnStep === InvoiceBtnStep.CheckFields ? 'case.invoice.validateFields' : 'case.invoice.validate') : 'case.invoice.validate'}
										tooltip={debitor.company === 'Ukendt debitor' ? 'Ukendt debitor. Sæt en anden debitor på.' : ''}
										onClick={async () => {
											if (invoiceERPReference.current == null) return;

											if (enabledcheckBcValueInSlutFaktura) {
												if (invoiceBtnStep === InvoiceBtnStep.CheckFields) {
													setInvoiceBtnStep(InvoiceBtnStep.Validate);
													setHighlightBcDifference(true);
													return;
												}
											}

											// Check if lines has been modified
											const linesWithoutHelpers = lines.map(({ renderKey, amount, cost, screening, screeningLinePosition, allowAttachDocument, screeningLineId, ...line }) => line);
											const modified = JSON.stringify(linesWithoutHelpers) !== JSON.stringify(invoiceDraftData?.createInvoice?.lines);

											// Update screening invoice
											if (type === InvoiceCategory.SCREENING) {
												const linesForScreening = lines.filter(l => typeof l.screening === 'undefined').map(({ renderKey, amount, cost, screening, screeningLinePosition, allowAttachDocument, screeningLineId, ...line }) => line);

												await updateScreeningInvoice({
													variables: {
														caseId: caseId,
														invoiceERPReference: invoiceERPReference.current,
														invoiceDescription: description,
														lines: linesForScreening.filter(line => line.type !== 'Comment'),
													},
												});
											}
											// Update the invoice
											else if (modified) {
												await updateInvoice({
													variables: {
														caseId: caseId,
														invoiceERPReference: invoiceERPReference.current,
														lines: linesWithoutHelpers,
														invoiceDescription: description,
													},
												});
											}

											// Validate the invoice
											try {
												await validateInvoice({
													variables: {
														caseId: caseId,
														invoiceERPReference: invoiceERPReference.current,
													},
												});
											} catch (e) {
												console.log(e);
											}

											setStep(InvoiceStep.Validation);
										}}
										//disabled={updatingInvoice || validatingInvoice || somelinesAreInvalid || debitor.company === 'Ukendt debitor'}
										loading={updatingInvoice || updatingScreeningInvoice}
									/>
								</div>

								{type !== InvoiceCategory.SCREENING && (
									<>
										<div className="border-1 flex flex-row gap-2 border-solid border-black">
											<div className="self-center text-xs">
												<p className="border-r-1 border-solid border-black px-2">
													{t('case.invoice.invoiceDate')}: {existingInvoice?.invoicePostingDate}
												</p>
											</div>

											<div className="self-center text-xs">
												<p>
													{t('case.invoice.invoiceStartDate')}: {existingInvoice?.invoiceStartDate}
												</p>
												<p>
													{t('case.invoice.invoiceEndDate')}: {existingInvoice?.invoiceEndDate}
												</p>
											</div>

											<div className="cursor-pointer self-center" onClick={() => setStep(InvoiceStep.OverrideDates)}>
												<ProgressIcon icon={faCalendarEdit} popperTitle="case.invoice.changeDates" iconColor={ProgressColor.BLUE} />
											</div>

											<div className="border-l-1 cursor-pointer self-center border-black px-2 text-xs" onClick={() => setIncludePreviousLines(!includePreviousLines)}>
												<FontAwesomeIcon icon={includePreviousLines ? faCheckSquare : faSquare} className="mr-1" />
												{t('case.invoice.includePreviousLines')}
											</div>

											<Button
												secondary
												text="case.invoice.repopulate"
												className="text-blue mr-4 self-center"
												style={{ height: '34px' }} // h-[34px] doesn't work
												disabled={updatingInvoice || validatingInvoice || suggestingInvoiceLines}
												onClick={async () => {
													if (invoiceERPReference.current == null) return;

													if (existingInvoice?.hasSuggestedLines && !window.confirm(t('case.invoice.invoiceResuggestLinesConfirmation'))) {
														return;
													}

													setInvoiceBtnStep(InvoiceBtnStep.CheckFields);
													setHighlightBcDifference(false);

													// Get the repopulate invoice data
													const result = await suggestInvoiceLines({
														variables: {
															caseId: caseId,
															invoiceERPReference: invoiceERPReference.current,
															includePreviousLines: includePreviousLines,
														},
													});

													const newLines = result.data?.suggestInvoiceLines?.map(toEditableInvoiceLine);
													if (newLines == null) return;

													newLines.sort((a, b) => a.sortIndex - b.sortIndex);

													const envFeeLines = lines.filter(l => l.no === DEFAULT_ENVFEE_NAME);
													// Overwrite all lines as the suggested invoice lines will contain those we manually created.
													setLines(current => newLines.length > 0 ? filterInvoiceLines([...newLines.map(nl => {
														const jobTaskNo = nl.taskNo;
														const foundJobTask = jobTasks.find(j => j.jobTaskNo === jobTaskNo);
														if (foundJobTask) {
															nl.excludeInEnvFee = foundJobTask.excludeInEnvFee ?? false;
														}
														return nl;
													}), ...envFeeLines]) : current);

													refetchInvoices().then(() => setEditRenderKey(Date.now()));
												}}
											/>
										</div>

										<div className="flex flex-row gap-2">
											<Button
												secondary
												text="case.invoice.addLine"
												onClick={() => {
													if (invoiceERPReference.current) {
														setLines([
															...lines,
															createBlankInvoiceLine(userName, caseNo, invoiceERPReference.current, invoicePostingDate, Math.max(...lines.map(cl => cl.sortIndex))),
														]);
													}
												}}
												disabled={updatingInvoice || validatingInvoice || !lastLineIsValid}
											/>
										</div>
									</>
								)}
							</div>
						</>
					),
				};

			case InvoiceStep.OverrideDates:
				return {
					bodyElement: (
						<>
							{(overridingInvoiceDates || loadingExistingInvoices) && <Loading />}
							<div className="flex w-full flex-row">
								<div className="flex flex-row gap-2">
									<Datepicker
										name="invoiceDate"
										title="case.invoice.invoiceDate"
										titleClass="self-center"
										className="text-blue ml-3"
										value={invoicePostingDate}
										onChange={e => setInvoicePostingDate(e.target.value)}
									/>
								</div>
							</div>
							<div className="flex w-full flex-row">
								<div className="flex flex-row gap-2">
									<label className="text-blue whitespace-no-wrap self-center text-base font-semibold">{t('case.invoice.invoicePeriod')}</label>
									<DateRangePicker
										startDate={invoiceStartDate}
										setStartDate={setInvoiceStartDate}
										endDate={invoiceEndDate}
										setEndDate={setInvoiceEndDate}
										forceShowEndDatePicker={true}
										forceHideTodayControls={true}
									/>
								</div>
							</div>
						</>
					),
					footerElement: (
						<div className="flex w-full flex-row justify-between">
							<Button
								primary
								text="common.save"
								disabled={overridingInvoiceDates || loadingExistingInvoices}
								onClick={async () => {
									if (invoiceERPReference.current == null) return;

									await overrideInvoiceDates({
										variables: {
											caseId: caseId,
											invoiceERPReference: invoiceERPReference.current,
											documentDate: invoicePostingDate,
											fromDate: invoiceStartDate.toISODate(),
											toDate: invoiceEndDate.toISODate(),
										},
									});

									await refetchInvoices();

									setStep(InvoiceStep.Edit);
								}}
							/>
							<Button secondary text="common.back" icon={faChevronLeft} onClick={() => setStep(InvoiceStep.Edit)} />
						</div>
					),
				};

			case InvoiceStep.Validation:
				return {
					bodyElement: (
						<InvoiceStepValidation
							loading={validatingInvoice}
							checks={invoiceValidationData?.validateInvoice ?? []}
							defaultValues={bypassReasons}
							setBypassReasonCode={(rule, reasonCode) =>
								setBypassReasons(current => {
									const result = current.slice();

									let item = result.find(c => c.rule === rule);
									if (typeof item === 'undefined') {
										const index =
											result.push({
												rule,
												reasonCode,
												comment: '',
											}) - 1;
										item = result[index];
									}
									item.reasonCode = reasonCode;

									return result;
								})
							}
							setBypassComment={(rule, comment) =>
								setBypassReasons(current => {
									const result = current.slice();

									let item = result.find(c => c.rule === rule);
									if (typeof item === 'undefined') {
										const index =
											result.push({
												rule,
												comment,
												reasonCode: '',
											}) - 1;
										item = result[index];
									}
									item.comment = comment;

									return result;
								})
							}
						/>
					),
					footerElement: (
						<div className="flex w-full flex-row justify-between">
							<div className="flex flex-row gap-2">
								<Button
									primary
									text="case.invoice.attachments"
									disabled={!validationOk || submittingBypassReasons}
									onClick={async () => {
										if (invoiceERPReference.current == null) return;

										await setInvoiceReasonCodes({
											variables: {
												caseId: caseId,
												invoiceERPReference: invoiceERPReference.current,
												validationRuleBypasses: bypassReasons,
											},
										});

										setStep(InvoiceStep.Attachments);
									}}
								/>

								<Button
									primary
									text="case.invoice.reviewSummary"
									disabled={!validationOk || submittingBypassReasons}
									onClick={async () => {
										if (invoiceERPReference.current == null) return;

										await setInvoiceReasonCodes({
											variables: {
												caseId: caseId,
												invoiceERPReference: invoiceERPReference.current,
												validationRuleBypasses: bypassReasons,
											},
										});

										setStep(InvoiceStep.Summary);
									}}
								/>
							</div>
							<Button secondary text="common.back" icon={faChevronLeft} onClick={() => {
								setStep(InvoiceStep.Edit);
								setInvoiceBtnStep(InvoiceBtnStep.CheckFields);
								setHighlightBcDifference(false);
							}} />
						</div>
					),
				};

			case InvoiceStep.Attachments:
				const loading = uploadingAttachments || deletingAttachment || invoiceAttachmentsResult.loading;

				return {
					bodyElement: (
						<InvocieStepAttachments
							deleteAttachment={async fileName => {
								if (invoiceERPReference.current === undefined) {
									return;
								}

								await deleteInvoiceFile({
									variables: {
										caseNo,
										invoiceNo: invoiceERPReference.current,
										fileName,
									},
								});
								await invoiceAttachmentsResult.refetch?.();
							}}
							attachments={invoiceAttachmentsResult.data?.invoice.attachments ?? []}
							loading={loading}
						/>
					),
					footerElement: (
						<div className="flex w-full flex-row justify-between">
							<Button primary text="case.invoice.reviewSummary" onClick={() => setStep(InvoiceStep.Summary)} disabled={loading} />

							<label className="rounded-default bg-blue inline-flex cursor-pointer flex-col items-center p-1 px-3 py-2 text-white focus:outline-none">
								<span>
									<FontAwesomeIcon icon={faFileUpload} className="mr-2" />
									{t('case.chooseFiles')}
								</span>
								<input
									name="documentUpload"
									id="documentUpload"
									type="file"
									autoComplete="nope"
									className="hidden"
									disabled={loading}
									onChange={async e => {
										if (e.target.files == null) return;
										if (e.target.files.length === 0) return;
										if (invoiceERPReference.current === undefined) {
											return;
										}

										const filesInputs = await fileListToFileInputList(e.target.files);
										await uploadInvoiceFiles({
											variables: {
												caseNo,
												invoiceNo: invoiceERPReference.current,
												files: filesInputs,
											},
										});
										await invoiceAttachmentsResult.refetch?.();
									}}
									multiple
								/>
							</label>
						</div>
					),
				};

			case InvoiceStep.Summary:
				const summaryLines = updateInvoiceData?.updateInvoice.lines ?? existingInvoicesData?.invoices.find(invoice => invoice.erpReferenceNo === invoiceERPReference.current)?.lines;
				if (summaryLines == null) throw new Error('No summary found');

				return {
					bodyElement: <InvoiceStepSummary category={type} lines={summaryLines} reasonCodes={bypassReasons} desciption={description} debitor={debitor} assortments={invoiceAssortments} screeningLines={screeningLines} />,
					footerElement: (
						<Button
							primary
							text={bypassReasons.length === 0 ? 'case.invoice.sendNoApprovalNeeded' : 'case.invoice.sendToApproval'}
							disabled={requestingInvoiceApproval}
							onClick={async () => {
								if (invoiceERPReference.current == null) return;

								if (bypassReasons.length === 0) {
									await approveInvoice({
										variables: {
											caseId: caseId,
											invoiceERPReference: invoiceERPReference.current,
										},
									});
								} else {
									await requestInvoiceApproval({
										variables: {
											caseId: caseId,
											invoiceERPReference: invoiceERPReference.current,
										},
									});
								}
								await refetch();
								close();
							}}
						/>
					),
				};
		}
	})();

	return <Modal size={size} title="case.invoice.link" visible={visible} close={close} body={bodyElement} footer={footerElement} />;
};

export default InvoiceModal;
