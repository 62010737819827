import { faFileSearch, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSearchCasesByErpNoQuery } from '@ssg/common/GraphQL/indexV2';
import classNames from 'classnames';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';

const NAME_OF_INPUT = 'universalCaseSearchField';

function useOutsideClick(ref, callback) {
	React.useEffect(() => {
		function handleClickOutside(event) {
			if (ref.current && !ref.current.contains(event.target)) {
				callback();
			}
		}

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [ref, callback]);
}

const isMac = () => {
	let isMac;
	// Use navigator.userAgentData for modern browsers
	if (navigator.userAgentData) {
		isMac = navigator.userAgentData.platform === 'macOS';
	} else {
		// Fallback for older browsers
		isMac = /Mac|iPod|iPhone|iPad/.test(navigator.userAgent);
	}
	// Use Ctrl for Windows/Linux and Command (metaKey) for Mac
	return isMac;
};

interface Props {
	menuCollapsed: boolean;
	setMenuCollapse: React.Dispatch<React.SetStateAction<boolean>>;
}

const CaseSearchField: React.FC<Props> = ({ menuCollapsed, setMenuCollapse }): React.ReactElement => {
	const [searchText, setSearchText] = React.useState<string>('');
	const [focusCount, setFocusCount] = React.useState<number>(0);
	const { push } = useHistory();
	const { data, loading } = useSearchCasesByErpNoQuery({
		variables: { erpNo: searchText },
		skip: searchText.length < 3,
	});

	const cases = React.useMemo(() => data?.searchCasesByERPNo || [], [data]);
	React.useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			// Use Ctrl for Windows/Linux and Command (metaKey) for Mac
			const correctModifierKey = isMac() ? event.metaKey : event.ctrlKey;
			if (correctModifierKey && event.key === 'k') {
				event.preventDefault();
				const inputElement = document.getElementById(NAME_OF_INPUT); // Select input by ID
				if (inputElement) inputElement.focus(); // Focus the input field
			}
		};

		window.addEventListener('keydown', handleKeyDown);

		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	}, []);

	const wrapperRef = React.useRef<HTMLDivElement>(null); // Create a ref for the component root
	const collapedRef = React.useRef(menuCollapsed);
	const inputRef = React.useRef<HTMLInputElement>(null); // Create a ref for the input element
	useOutsideClick(wrapperRef, () => {
		if (focusCount > 0) {
			setSearchText('');
			setMenuCollapse(collapedRef.current);
			setFocusCount(0);
		}
	});

	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter' && cases.length > 0) {
			event.preventDefault();
			push(`/case/${cases[0].id}`);
			setSearchText('');
			setMenuCollapse(collapedRef.current);
			setFocusCount(0);
			if (inputRef.current) inputRef.current.blur();
		}
	};

	return (
		<div className="relative h-14" ref={wrapperRef}>
			<div className='flex flex-row justify-between items-center px-2'>
				<p className="opacity-75" style={{ height: '16px', lineHeight: '16px', marginTop: '5px' }}>
					{menuCollapsed ? <FontAwesomeIcon className="ml-1 mt-1" icon={faFileSearch} /> : 'Gå til sag ...'}
				</p>
				<div className={classNames('flex flex-row space-x-1 pl-1 pt-1 items-center', { 'hidden': menuCollapsed })}>
					<Keycap text={isMac() ? 'CMD' : 'CTRL'} />
					<div>
						+
					</div>
					<Keycap text="K" />
				</div>
			</div>
			<input
				className={classNames('w-full bg-transparent focus:bg-white border-1 border-white rounded-lg text-blue py-1 px-2 absolute top-0', {
					'bg-white': searchText.length > 0,
				})}
				id={NAME_OF_INPUT}
				value={searchText}
				onChange={(e) => setSearchText(e.target.value)}
				onFocus={() => {
					collapedRef.current = menuCollapsed;
					setFocusCount(1);
					setSearchText('');
					setMenuCollapse(false);
				}}
				onKeyDown={handleKeyDown}
				autoComplete='off'
				ref={inputRef}
			/>

			<div className="bg-white text-blue mt-2 rounded-default">
				{cases.map((c) => <Link key={c.id} className="hover:underline focus:underline focus-within:underline" onClick={() => {
					setSearchText('');
					setMenuCollapse(collapedRef.current);
					setFocusCount(0);
				}} to={`/case/${c.id}`}><p className="pl-2 py-1">{c.erpNo}</p></Link>)}
				{!loading && cases.length === 0 && searchText.length > 2 && <p className="pl-2 py-1">Ingen sager fundet</p>}
				{loading && <p className="pl-2 py-1"><FontAwesomeIcon className="animate-spin" icon={faSpinner} /> Ingen sager fundet</p>}
			</div>

		</div>
	);
};

interface KeycapProps {
	text: string;
}

const Keycap: React.FC<KeycapProps> = ({ text }): React.ReactElement => {

	return (
		<div className="flex items-center border-1 px-1 rounded-default border-white bg-opacity-10 bg-white pt-px" style={{ fontSize: '0.6rem', height: '20px' }}>{text}</div>
	);
};


export default CaseSearchField;