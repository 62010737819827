// TIMEREG. HOOKS
import {
	GetDrivingSlipCategoriesQuery,
	GetPaymentSupplementsQuery,
	GetTimeTrackingDayClosedByUserQuery,
	GetTimeTrackingDaysQuery,
	GetTimeTrackingsDayForUserDocument,
	GetTimeTrackingsDayForUserQuery,
	GetTimeTrackingsDayForUserQueryVariables,
	GetUserFlexTimeQuery,
	GetWebCasesByErpNoQuery,
	GetWebCasesForTimeTrackingQuery,
	GetWebDrivingSlipQuery,
	GetWebDrivingSlipsNoCaseQuery,
	GetrejectedTimeTrackingsDayForUserQuery,
	JobTasksQuery,
	OpenRejectedTimeTrackingDayMutationVariables,
	SearchWebUsersQuery,
	SupplementType,
	TimeTrackingDayInputType,
	TimeTrackingDayStatus,
	TimeTrackingEntryInputType,
	TimeTrackingEntryUpdateInputType,
	TrackingType,
	UserBasisTimeQuery,
	useCreateTimeTrackingDayMutation,
	useDeleteTimeTrackingDayEntryMutation,
	useDeleteTimeTrackingDayMutation,
	useDeleteTimeTrackingEntrySupplementMutation,
	useGetDrivingSlipCategoriesQuery,
	useGetPaymentSupplementsQuery,
	useGetTimeTrackingDayClosedByUserQuery,
	useGetTimeTrackingDaysQuery,
	useGetTimeTrackingsDayForUserQuery,
	useGetUserFlexTimeQuery,
	useGetWebCasesByErpNoQuery,
	useGetWebCasesForTimeTrackingQuery,
	useGetWebDrivingSlipQuery,
	useGetWebDrivingSlipsNoCaseQuery,
	useGetrejectedTimeTrackingsDayForUserQuery,
	useJobTasksQuery,
	useOpenRejectedTimeTrackingDayMutation,
	useReopenTimeTrackingDayMutation,
	useSearchWebUsersQuery,
	useSubmitTimeTrackingDayMutation,
	useUpdateTimeTrackingDayEntryMutation,
	useUserBasisTimeQuery,
} from '@ssg/common/GraphQL/indexV2';
import { ApolloQueryResult } from '@apollo/client';
import { SelectOption } from '@ssg/common/Helpers/Helpers';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

// QUERIES

// Get user timeregs. based on user id and selected date (status rejected are filtered out)
export function useTimeTrackingsDayForUserData(
	userId: string,
	selectedDate: string,
	rejectMode?: boolean,
): {
	timeTrackingsDayLoading: boolean;
	timeTrackingsDayDataRaw: GetTimeTrackingsDayForUserQuery | undefined;
	refetchTimeTrackingsDay: () => Promise<ApolloQueryResult<GetTimeTrackingsDayForUserQuery>>;
} {
	const {
		data: timeTrackingsDayDataRaw,
		loading: timeTrackingsDayLoading,
		refetch: refetchTimeTrackingsDay,
	} = useGetTimeTrackingsDayForUserQuery({
		variables: {
			user: userId,
			minDate: selectedDate,
			maxDate: selectedDate,
		},
		skip: selectedDate === '' || userId === '' || rejectMode,
	});

	return {
		timeTrackingsDayLoading: timeTrackingsDayLoading,
		timeTrackingsDayDataRaw: timeTrackingsDayDataRaw,
		refetchTimeTrackingsDay: refetchTimeTrackingsDay,
	};
}

// Get time tracking days based on user id and date range
export function useGetTimeTrackingDaysQueryData(
	minDate: string,
	maxDate: string,
	users?: string[] | string,
	timeTrackingDayStatus?: TimeTrackingDayStatus,
): {
	timeTrackingDaysLoading: boolean;
	timeTrackingDaysDataRaw: GetTimeTrackingDaysQuery | undefined;
	refetchTimeTrackingDays: () => Promise<ApolloQueryResult<GetTimeTrackingDaysQuery>>;
} {
	const {
		data: timeTrackingDaysDataRaw,
		loading: timeTrackingDaysLoading,
		refetch: refetchTimeTrackingDays,
	} = useGetTimeTrackingDaysQuery({
		variables: {
			minDate: minDate,
			maxDate: maxDate,
			users: users,
			timeTrackingDayStatus: timeTrackingDayStatus,
		},
	});

	return {
		timeTrackingDaysLoading: timeTrackingDaysLoading,
		timeTrackingDaysDataRaw: timeTrackingDaysDataRaw,
		refetchTimeTrackingDays: refetchTimeTrackingDays,
	};
}

// Get drivingSlips
export function useDrivingSlipsData(id: string | undefined): {
	drivingSlipLoading: boolean;
	drivingSlipDataRaw: GetWebDrivingSlipQuery | undefined;
} {
	const { data: drivingSlipDataRaw, loading: drivingSlipLoading } = useGetWebDrivingSlipQuery({
		variables: { id: id ?? '' },
		skip: id === '' || typeof id === 'undefined',
	});

	return {
		drivingSlipLoading: drivingSlipLoading,
		drivingSlipDataRaw: drivingSlipDataRaw,
	};
}

// Get user rejected timetrackings
export function useRejectedTimeTrackingsForUserQuery(
	userId: string,
	RejectedTimeRegistrationWarningFlag: boolean,
): {
	rejectedTimeTrackingLoading: boolean;
	rejectedTimeTrackingDataRaw: GetrejectedTimeTrackingsDayForUserQuery | undefined;
	refetchRejectedTimeTrackings: () => Promise<ApolloQueryResult<GetrejectedTimeTrackingsDayForUserQuery>>;
} {
	const { data: rejectedTimeTrackingDataRaw, loading: rejectedTimeTrackingLoading, refetch: refetchRejectedTimeTrackings } = useGetrejectedTimeTrackingsDayForUserQuery({
		fetchPolicy: 'network-only',
		variables: { user: userId ?? '' },
		skip: userId === '' || typeof userId === 'undefined' || !RejectedTimeRegistrationWarningFlag,
	});

	return {
		rejectedTimeTrackingLoading: rejectedTimeTrackingLoading,
		rejectedTimeTrackingDataRaw: rejectedTimeTrackingDataRaw,
		refetchRejectedTimeTrackings: refetchRejectedTimeTrackings,
	};
}

// Get jobtasks from BC
export function useJobTasksDataQuery(erpReferenceNo: string): {
	jobTaskLoading: boolean;
	jobTaskDataRaw: JobTasksQuery | undefined;
} {
	const { data: jobTaskDataRaw, loading: jobTaskLoading } = useJobTasksQuery({
		fetchPolicy: 'cache-and-network',
		variables: {
			erpReferenceNo: erpReferenceNo,
		},
		skip: erpReferenceNo === '',
	});

	return {
		jobTaskLoading: jobTaskLoading,
		jobTaskDataRaw: jobTaskDataRaw,
	};
}

// Get closed day (also gets status rejected)
export function useClosedDayByUserQuery(userId: string): {
	closedDayRaw: GetTimeTrackingDayClosedByUserQuery | undefined;
	refetchClosedDay: () => Promise<ApolloQueryResult<GetTimeTrackingDayClosedByUserQuery>>;
} {
	const { data: closedDayRaw, refetch: refetchClosedDay } = useGetTimeTrackingDayClosedByUserQuery({
		fetchPolicy: 'no-cache',
		variables: {
			user: userId,
		},
		skip: userId === '',
	});

	return {
		closedDayRaw: closedDayRaw,
		refetchClosedDay: refetchClosedDay,
	};
}

// Get users
export function useSearchUsersQuery(
	searchText: string,
	onlyEmployees: boolean,
	checkInBc: boolean,
): {
	usersLoading: boolean;
	usersDataRaw: SearchWebUsersQuery | undefined;
} {
	const { data: usersDataRaw, loading: usersLoading } = useSearchWebUsersQuery({
		variables: {
			searchText: searchText,
			onlyEmployees: onlyEmployees,
			checkInBc: checkInBc,
		},
		skip: searchText === '',
	});

	return {
		usersLoading: usersLoading,
		usersDataRaw: usersDataRaw,
	};
}

// Get user basis time
export function useUserBasisTimeDataQuery(
	userId: string,
	selectedDate: string,
): {
	basisTimeRaw: UserBasisTimeQuery | undefined;
} {
	const { data: basisTimeRaw } = useUserBasisTimeQuery({
		fetchPolicy: 'cache-and-network',
		variables: {
			user: userId ?? '',
			date: selectedDate,
		},
		skip: userId === '' || selectedDate === '',
	});

	return {
		basisTimeRaw: basisTimeRaw,
	};
}

// Get user flex time
export function useUserFlexTimeQuery(
	userId: string,
): {
	userFlexTime: GetUserFlexTimeQuery | undefined;
} {
	const { data: userFlexTime } = useGetUserFlexTimeQuery({
		fetchPolicy: 'no-cache',
		variables: {
			user: userId ?? '',
		},
		skip: userId === '',
	});

	return {
		userFlexTime: userFlexTime,
	};
}

// Get cases data
export function useWebCasesForTimeTrackingQuery(
	erpReferenceNos: string[],
	includeEconomy: boolean,
	drivingSlipLoading: boolean,
	timeTrackingsDayLoading: boolean,
): {
	casesLoading: boolean;
	caseDataRaw: GetWebCasesForTimeTrackingQuery | undefined;
} {
	/* TODO: Find out if this is the wrong GQL */
	const { data: caseDataRaw, loading: casesLoading } = useGetWebCasesForTimeTrackingQuery({
		fetchPolicy: 'cache-and-network',
		variables: {
			erpReferenceNos: erpReferenceNos,
			includeEconomy: includeEconomy,
		},
		skip: drivingSlipLoading || timeTrackingsDayLoading || erpReferenceNos.length === 0,
	});

	return {
		casesLoading: casesLoading,
		caseDataRaw: caseDataRaw,
	};
}

// Get cases by erpNo
export function useWebCasesByErpNoQuery(
	caseSearchText: string,
	closedCases: boolean,
	appliedClosed: boolean,
): {
	erpNoCasesRaw: GetWebCasesByErpNoQuery | undefined;
} {
	const { data: erpNoCasesRaw } = useGetWebCasesByErpNoQuery({
		variables: {
			erpReferenceNo: '',
			closedCases: closedCases,
			appliedClosed: appliedClosed,
			searchString: caseSearchText,
		},
		skip: caseSearchText.length < 3,
	});

	return {
		erpNoCasesRaw: erpNoCasesRaw,
	};
}

// Get drivingslip no case
export function useWebDrivingSlipNoCaseQuery(
	erpNoCases: GetWebCasesByErpNoQuery | undefined,
	selectedCase: SelectOption | undefined,
	drivingSlipLoading: boolean,
	jobTask: string,
): {
	drivingSlipsNoCaseDataRaw: GetWebDrivingSlipsNoCaseQuery | undefined;
} {
	const { data: drivingSlipsNoCaseDataRaw } = useGetWebDrivingSlipsNoCaseQuery({
		variables: {
			case: erpNoCases?.cases.find(c => c.erpNo === selectedCase?.value)?.id,
		},
		skip: drivingSlipLoading || jobTask !== 'DRIVINGSLIP' || typeof selectedCase === 'undefined' || typeof erpNoCases === 'undefined',
	});

	return {
		drivingSlipsNoCaseDataRaw: drivingSlipsNoCaseDataRaw,
	};
}

// Get drivingslips categories
export function useDrivingSlipCategoriesQuery(): {
	drivingSlipCategoriesDataRaw: GetDrivingSlipCategoriesQuery | undefined;
} {
	const { data: drivingSlipCategoriesDataRaw } = useGetDrivingSlipCategoriesQuery();

	return {
		drivingSlipCategoriesDataRaw: drivingSlipCategoriesDataRaw,
	};
}

// Get payment supplements
export function usePaymentSupplementsQuery(): {
	paymentSupplementsDataRaw: GetPaymentSupplementsQuery | undefined;
} {
	const { data: paymentSupplementsDataRaw } = useGetPaymentSupplementsQuery();

	return {
		paymentSupplementsDataRaw: paymentSupplementsDataRaw,
	};
}

// MUTATIONS
// Create time tracking
export function useCreateTimeTracking(): {
	createTimeTrackingLoading: boolean;
	onSubmit: (timeTrackingDay: TimeTrackingDayInputType, timeTrackingEntry: TimeTrackingEntryInputType) => Promise<void>;
} {
	const [createTimeTracking, { loading: createTimeTrackingLoading }] = useCreateTimeTrackingDayMutation();

	const onSubmit = async (timeTrackingDay: TimeTrackingDayInputType, timeTrackingEntry: TimeTrackingEntryInputType) => {
		const getDayDate = () => DateTime.fromJSDate(new Date(timeTrackingDay.date));

		const dayDate = getDayDate();

		const convertedRangedEndDateToISO = timeTrackingDay.rangeEndDate ? DateTime.fromJSDate(new Date(timeTrackingDay.rangeEndDate)).toISO() : undefined;

		const startDate = DateTime.fromFormat(timeTrackingEntry.startDate, 'yyyy-MM-dd', { zone: 'utc' }).toISO();
		const endDate = DateTime.fromFormat(timeTrackingEntry.endDate, 'yyyy-MM-dd', { zone: 'utc' }).toISO();

		const standAloneRegistration = timeTrackingEntry.addonLines?.some(a => a.paymentSupplementCode && shiftSupplements.includes(a.paymentSupplementCode));

		const onCall = timeTrackingEntry.onCall || timeTrackingEntry.onCallback;

		try {
			await createTimeTracking({
				variables: {
					timeTrackingDay: {
						user: timeTrackingDay.user,
						date: dayDate.toISO(),
						isPartOfRange: timeTrackingDay.isPartOfRange,
						rangeEndDate: convertedRangedEndDateToISO,
					},
					timeTrackingEntry: {
						user: timeTrackingDay.user,
						startDate,
						endDate,
						startTime: standAloneRegistration ? '00:00' : timeTrackingEntry.startTime.split('D')[0],
						endTime: standAloneRegistration ? '00:00' : timeTrackingEntry.endTime.split('D')[0],
						hours: standAloneRegistration ? 0 : timeTrackingEntry.hours,
						jobNo: timeTrackingEntry.jobNo,
						jobTaskName: timeTrackingEntry.jobTaskName,
						jobTaskNo: timeTrackingEntry.jobTaskNo,
						case: timeTrackingEntry.case,
						drivingSlip: timeTrackingEntry.drivingSlip === '' ? null : timeTrackingEntry.drivingSlip,
						onCall: onCall,
						onCallback: timeTrackingEntry.onCallback,
						includesLunch: timeTrackingEntry.includesLunch,
						remark: timeTrackingEntry.remark,
						timeTrackingStatus: timeTrackingEntry.timeTrackingStatus,
						trackingType: TrackingType.Timeregistration,
						workTypeCode: timeTrackingEntry.workTypeCode,
						addonLines: timeTrackingEntry.addonLines?.map(line => ({
							...line,
							hours: line.supplementType === SupplementType.Piecework ? 1 : Number(line.hours),
							pieceworkAmount: line.supplementType === SupplementType.Piecework ? line.hours : 0,
							onCall: onCall,
						})),
					},
				},
				update: (cache, { data }): void => {
					if (typeof data === 'undefined' || data === null || data.createTimeTrackingDay === null) {
						return;
					}

					const cachedRequest = cache.readQuery<GetTimeTrackingsDayForUserQuery, GetTimeTrackingsDayForUserQueryVariables>({
						query: GetTimeTrackingsDayForUserDocument,
						variables: {
							user: timeTrackingDay.user,
							minDate: timeTrackingDay.date,
							maxDate: timeTrackingDay.date,
						},
					});

					if (cachedRequest === null) {
						return;
					}

					if (cachedRequest.timeTrackingsDayForUser !== null) {
						return;
					}

					cache.writeQuery<GetTimeTrackingsDayForUserQuery, GetTimeTrackingsDayForUserQueryVariables>({
						query: GetTimeTrackingsDayForUserDocument,
						variables: {
							user: timeTrackingDay.user,
							minDate: timeTrackingDay.date,
							maxDate: timeTrackingDay.date,
						},
						data: {
							timeTrackingsDayForUser: data.createTimeTrackingDay,
						},
					});
				},
			});

		} catch (e) {
			console.log(e);
		}
	};

	return {
		createTimeTrackingLoading: createTimeTrackingLoading,
		onSubmit,
	};
}

// Update time tracking
export function useUpdateTimeTracking(): {
	updateTimeTrackingLoading: boolean;
	onSubmitUpdate: (timeTrackingDayId: string, timeTrackingEntry: TimeTrackingEntryUpdateInputType) => Promise<void>;
} {
	const [updateTimeTracking, { loading: updateTimeTrackingLoading }] = useUpdateTimeTrackingDayEntryMutation();

	const onSubmitUpdate = async (timeTrackingDayId: string, timeTrackingEntry: TimeTrackingEntryUpdateInputType) => {

		const date = DateTime.fromFormat(timeTrackingEntry.date, 'yyyy-MM-dd', { zone: 'utc' }).toISO();
		const startDate = DateTime.fromFormat(timeTrackingEntry.startDate, 'yyyy-MM-dd', { zone: 'utc' }).toISO();
		const endDate = DateTime.fromFormat(timeTrackingEntry.endDate, 'yyyy-MM-dd', { zone: 'utc' }).toISO();

		const standAloneRegistration = timeTrackingEntry.addonLines?.some(a => a.paymentSupplementCode && shiftSupplements.includes(a.paymentSupplementCode));

		const onCall = timeTrackingEntry.onCall || timeTrackingEntry.onCallback;

		try {
			await updateTimeTracking({
				variables: {
					timeTrackingDayId: timeTrackingDayId ?? '',
					timeTrackingEntry: {
						parentId: timeTrackingDayId ?? '',
						id: timeTrackingEntry.id,
						date,
						startDate,
						endDate,
						user: timeTrackingEntry.user,
						startTime: standAloneRegistration ? '00:00' : timeTrackingEntry.startTime.split('D')[0],
						endTime: standAloneRegistration ? '00:00' : timeTrackingEntry.endTime.split('D')[0],
						hours: standAloneRegistration ? 0 : timeTrackingEntry.hours,
						jobNo: timeTrackingEntry.jobNo,
						jobTaskName: timeTrackingEntry.jobTaskName,
						jobTaskNo: timeTrackingEntry.jobTaskNo,
						case: timeTrackingEntry.case,
						drivingSlip: timeTrackingEntry.drivingSlip === '' ? null : timeTrackingEntry.drivingSlip,
						onCall: onCall,
						onCallback: timeTrackingEntry.onCallback,
						includesLunch: timeTrackingEntry.includesLunch,
						remark: timeTrackingEntry.remark,
						timeTrackingStatus: timeTrackingEntry.timeTrackingStatus,
						trackingType: TrackingType.Timeregistration,
						workTypeCode: timeTrackingEntry.workTypeCode,
						addonLines: timeTrackingEntry.addonLines?.map(line => ({
							parentId: timeTrackingEntry.id,
							user: timeTrackingEntry.user,
							id: line.id,
							case: line.case,
							date: line.date,
							drivingSlip: line.drivingSlip,
							hours: line.supplementType === SupplementType.Piecework ? 1 : Number(line.hours),
							pieceworkAmount: line.supplementType === SupplementType.Piecework ? line.hours : 0,
							jobNo: line.jobNo,
							jobTaskName: line.jobTaskName,
							jobTaskNo: line.jobTaskNo,
							onCall: onCall,
							onCallback: line.onCallback,
							paymentSupplementCode: line.paymentSupplementCode,
							paymentSupplementName: line.paymentSupplementName,
							remark: line.remark,
							supplementType: line.supplementType,
							supplementUom: line.supplementUom,
							timeTrackingStatus: line.timeTrackingStatus,
							workTypeCode: line.workTypeCode,
						})),
					},
				},
				update: (cache, { data }): void => {
					if (typeof data === 'undefined' || data === null || data.updateTimeTrackingDayEntry === null) {
						return;
					}

					const cachedRequest = cache.readQuery<GetTimeTrackingsDayForUserQuery, GetTimeTrackingsDayForUserQueryVariables>({
						query: GetTimeTrackingsDayForUserDocument,
						variables: {
							user: timeTrackingEntry.user,
							minDate: timeTrackingEntry.date,
							maxDate: timeTrackingEntry.date,
						},
					});

					if (cachedRequest === null) {
						return;
					}

					if (cachedRequest.timeTrackingsDayForUser !== null) {
						return;
					}

					cache.writeQuery<GetTimeTrackingsDayForUserQuery, GetTimeTrackingsDayForUserQueryVariables>({
						query: GetTimeTrackingsDayForUserDocument,
						variables: {
							user: timeTrackingEntry.user,
							minDate: timeTrackingEntry.date,
							maxDate: timeTrackingEntry.date,
						},
						data: {
							timeTrackingsDayForUser: data.updateTimeTrackingDayEntry,
						},
					});
				},
			});

		} catch (e) {
			console.log(e);
		}
	};

	return {
		updateTimeTrackingLoading: updateTimeTrackingLoading,
		onSubmitUpdate,
	};
}

// Delete TimeTrackingDay
export function useDeleteTimeTrackingDay(): {
	deleteTimeTrackingLoading: boolean;
	deleteTimeTrackingDay: (dayId: string, rangeDelete?: boolean) => Promise<void>;
} {
	const [deleteTimeTrackingDayMutation, { loading: deleteTimeTrackingLoading }] = useDeleteTimeTrackingDayMutation();

	const { t } = useTranslation();

	const deleteTimeTrackingDay = async (dayId: string, rangeDelete = false) => {
		const confirm = window.confirm(rangeDelete ? t('timeRegistration.wantToDeleteRange') : t('timeRegistration.wantToDeleteDay'));
		if (confirm) {
			try {
				await deleteTimeTrackingDayMutation({
					variables: {
						dayId: dayId,
					},
				});
			} catch (e) {
				console.log(e);
			}
		}
	};

	return {
		deleteTimeTrackingLoading: deleteTimeTrackingLoading,
		deleteTimeTrackingDay,
	};
}

// Delete TimeTrackingDayEntry
export function useDeleteTimeTrackingDayEntry(): {
	deleteTimeTrackingDayEntryLoading: boolean;
	deleteTimeTrackingDayEntry: (dayId: string, entryId: string) => Promise<void>;
} {
	const [deleteTimeTrackingDayEntryMutation, { loading: deleteTimeTrackingDayEntryLoading }] = useDeleteTimeTrackingDayEntryMutation();

	const { t } = useTranslation();

	const deleteTimeTrackingDayEntry = async (dayId: string, entryId: string) => {
		const confirm = window.confirm(t('timeRegistration.wantToDeleteEntry'));
		if (confirm) {
			try {
				await deleteTimeTrackingDayEntryMutation({
					variables: {
						dayId: dayId,
						entryId: entryId,
					},
				});
			} catch (e) {
				console.log(e);
			}
		}
	};

	return {
		deleteTimeTrackingDayEntryLoading: deleteTimeTrackingDayEntryLoading,
		deleteTimeTrackingDayEntry,
	};
}

// Delete TimeTrackingDayEntrySupplement
export function useDeleteTimeTrackingDayEntrySupplement(): {
	deleteTimeTrackingEntrySupplementLoading: boolean;
	deleteTimeTrackingEntrySupplement: (dayId: string, entryId: string, supplementId: string) => Promise<void>;
} {
	const [deleteTimeTrackingEntrySupplementMutation, { loading: deleteTimeTrackingEntrySupplementLoading }] = useDeleteTimeTrackingEntrySupplementMutation();

	const { t } = useTranslation();

	const deleteTimeTrackingEntrySupplement = async (dayId: string, entryId: string, supplementId: string) => {
		const confirm = window.confirm(t('timeRegistration.wantToDeleteSupplement'));
		if (confirm) {
			try {
				await deleteTimeTrackingEntrySupplementMutation({
					variables: {
						dayId: dayId,
						entryId: entryId,
						supplementId: supplementId,
					},
				});
			} catch (e) {
				console.log(e);
			}
		}
	};

	return {
		deleteTimeTrackingEntrySupplementLoading: deleteTimeTrackingEntrySupplementLoading,
		deleteTimeTrackingEntrySupplement,
	};
}

// Re-open rejected timetracking day
export function useOpenRejectedTimeTrackingDay() {
	const [openRejectedTimeTrackingDayMutation, { loading: openRejectedTimeTrackingDayLoading }] = useOpenRejectedTimeTrackingDayMutation();

	const openRejectedTimeTrackingDay = async (variables: OpenRejectedTimeTrackingDayMutationVariables) => {
		try {
			await openRejectedTimeTrackingDayMutation({
				variables,
			});
		} catch (e) {
			console.log(e);
		}
	};

	return {
		openRejectedTimeTrackingDayLoading,
		openRejectedTimeTrackingDay,
	};
}

// Submit day
export function useSubmitDay(): {
	submitLoading: boolean;
	closeDay: (timeTracking: TimeTrackingDayInputType) => Promise<void>;
} {
	const [submitTimeTrackingDay, { loading: submitLoading }] = useSubmitTimeTrackingDayMutation();

	const closeDay = async (timeTracking: TimeTrackingDayInputType) => {
		if (typeof timeTracking === 'undefined' || timeTracking === null) return;

		const convertedDateToISO = DateTime.fromJSDate(new Date(timeTracking.date)).toISO();

		try {
			await submitTimeTrackingDay({
				variables: {
					timeTrackingDay: {
						date: convertedDateToISO,
						user: timeTracking.user,
						isPartOfRange: timeTracking.isPartOfRange,
						rangeEndDate: timeTracking.rangeEndDate,
					},
				},
				update: (cache, { data }): void => {
					if (typeof data === 'undefined' || data === null || data.submitTimeTrackingDay === null) {
						return;
					}

					const cachedRequest = cache.readQuery<GetTimeTrackingsDayForUserQuery, GetTimeTrackingsDayForUserQueryVariables>({
						query: GetTimeTrackingsDayForUserDocument,
						variables: {
							user: timeTracking.user,
							minDate: timeTracking.date,
							maxDate: timeTracking.date,
						},
					});

					if (cachedRequest === null) {
						return;
					}

					if (cachedRequest.timeTrackingsDayForUser !== null) {
						return;
					}

					cache.writeQuery<GetTimeTrackingsDayForUserQuery, GetTimeTrackingsDayForUserQueryVariables>({
						query: GetTimeTrackingsDayForUserDocument,
						variables: {
							user: timeTracking.user,
							minDate: timeTracking.date,
							maxDate: timeTracking.date,
						},
						data: {
							timeTrackingsDayForUser: data.submitTimeTrackingDay,
						},
					});
				},
			});
		} catch (e) {
			console.log(e);
		}
	};

	return {
		submitLoading: submitLoading,
		closeDay,
	};
}

// Reopen day
export function useReopenDay(): {
	reopenLoading: boolean;
	openDay: (timeTracking: TimeTrackingDayInputType) => Promise<void>;
} {
	const [reopenTimeTrackingDay, { loading: reopenLoading }] = useReopenTimeTrackingDayMutation();

	const openDay = async (timeTracking: TimeTrackingDayInputType) => {
		if (typeof timeTracking === 'undefined' || timeTracking === null) return;

		const convertedDateToISO = DateTime.fromJSDate(new Date(timeTracking.date)).toISO();

		try {
			await reopenTimeTrackingDay({
				variables: {
					timeTrackingDay: {
						date: convertedDateToISO,
						user: timeTracking.user,
						isPartOfRange: timeTracking.isPartOfRange,
						rangeEndDate: timeTracking.rangeEndDate,
					},
				},
			});
		} catch (e) {
			console.log(e);
		}
	};

	return {
		reopenLoading: reopenLoading,
		openDay,
	};
}


// Helper Functions / ressources

export const activityCode: SelectOption[] = [
	{ label: 'Intern', value: 'INTERN' },
	{ label: 'Køreseddel', value: 'DRIVINGSLIP' },
	{ label: 'Sag', value: 'CASE' },
];

// SALARY TYPES
export const sanitoer = ['20700', '20970'];

export const industrimedarbejder = ['10000', '100002', '20900'];

export const funktionaer = ['88985', 'Funk'];

export const tomroerSnedker = ['02100', '02300', '06110', '06160'];

export const vagtcentral = ['VAGT'];

// SUPPLEMENTS FILTERING
// Auto. supplements will be hidden from supplement options
export const automaticSupplements = [
	'2100', // Overtid 1
	'2101', // Overtid 2
	'2105', // Overtid (INTERN)
	'8599', // Overtid (INTERN)
	'2051', // Aften
	'2052', // Aften (INTERN)
	'2054', // Nat
	'2055', // Nat (INTERN)
	'2095', // Lørdag
	'2056', // Søndag
	'2057', // Søndag (INTERN)
];

export const automaticSupplementsFunk = [
	'2101', // Overtid 2
	'2051', // Aften
	'2052', // Aften (INTERN)
	'2054', // Nat
	'2055', // Nat (INTERN)
	'2056', // Søndag
	'2057', // Søndag (INTERN)
];

// Only one of these is allowed to be added per. day
export const shiftSupplements = [
	'2092', // Bagvagttillæg hverdag VC
	'2093', // Bagvagttillæg weekend VC
	'2094', // Vagttillæg Vagtmand (Sanitør)
	'2150', // Vagttillæg Vagtleder
];

export const pieceworkSupplements = [
	'8401', // Akkord (Timer)
	'8402', // Akkord (Beløb)
];

// List of allowed supps for sanitarians (Sanitør)
export const sanitoerSupplements = [
	'2180', // Asbest
	'8010', // Kørselstid
	'8011', // Kørselstid (INTERN)
	'8004', // kørte km i egen vogn
	'2190', // Særligt
	'2094', // Vagttillæg Vagtmand (Sanitør)
	'2150', // Vagttillæg Vagtleder
	'2170', // Beregnet tillæg
	'2171', // Beregnet tillæg (INTERN)
	'8600', // Afvarsling
	'8640', // Udkald tillæg
	'8401', // Akkord (Timer)
	'8402', // Akkord (Beløb)
];

// List of allowed supps for industrial workers (Industrimedarbejder)
export const industrimedarbejderSupplements = [
	'2180', // Asbest
	'2197', // Gene
	'8010', // Kørselstid
	'8011', // Kørselstid (INTERN)
	'8004', // kørte km i egen vogn
	'2198', // Skadeservice
	'2190', // Særligt
	'2094', // Vagttillæg Vagtmand (Sanitør)
	'2150', // Vagttillæg Vagtleder
	'2170', // Beregnet tillæg
	'2171', // Beregnet tillæg (INTERN)
	'8610', // Hovedrengøring
	'8620', // Skur tillæg
	'8630', // Varsko tillæg
	'8640', // Udkald tillæg
	'8401', // Akkord (Timer)
	'8402', // Akkord (Beløb)
];

// List of allowed supps for white-collar workers (Funktionær)
export const funktionaerSupplements = [
	'2100', // Overtid 1 supplement
	'2105', // Overtid (INTERN)
	'8004', // kørte km i egen vogn
	'2094', // Vagttillæg Vagtmand (Sanitør)
	'2150', // Vagttillæg Vagtleder
];

// List of allowed supps for carpenter, carpenter apprentice and painter (Tømrer / snedker)
export const tomroerSnedkerSupplements = [
	'8004', // kørte km i egen vogn
	'2195', // Smuds
	'2094', // Vagttillæg Vagtmand (Sanitør)
	'2150', // Vagttillæg Vagtleder
	'2170', // Beregnet tillæg
	'2171', // Beregnet tillæg (INTERN)
	// '8401', // Akkord (Timer)
	// '8402', // Akkord (Beløb)
];

// Should only be available for dispatch workers (Vagtcentral)
export const vagtcentralSupplements = [
	'8004', // kørte km i egen vogn
	'2100', // Overtid 1 supplement
	'2105', // Overtid (INTERN)
	'2092', // Bagvagttillæg hverdag VC
	'2093', // Bagvagttillæg weekend VC
];

// JOBTASK FILTERING
// Should be only available options for ranged dates
export const rangedJobTasks = [
	'002', // Syg
	'004', // Fri for egen regning
	'005', // Barsel
	'007', // Feriefridag
	'008', // Feriedag
	'013', // Fravær pga. arbejdsskade.
	'015', // Fravær pga. COVID-19
	'017', // Hjemsendelse
	'021', // Skole ophold - IT student
	'022', // Syg - Langtidssydom
	'0034', // §56 sygdom
];

// Round to the nearest 0.25
export function roundToNearestQuarter(hour: number): number {
	return Math.round(hour * 4) / 4;
}

export function calculateHours(startStr: string | undefined, endStr: string | undefined): number {
	if (typeof startStr === 'undefined' || typeof endStr === 'undefined') {
		return 0;
	}
	const [startTime, startDate] = startStr.split('D');
	const [endTime, endDate] = endStr.split('D');
	const [startHour, startMinute] = startTime.split(':').map(Number);
	const [endHour, endMinute] = endTime.split(':').map(Number);

	const startTimeHours = startHour + startMinute / 60;
	const endTimeHours = endHour + endMinute / 60;

	let hoursDiff = endTimeHours - startTimeHours;

	// Handling cases where the end time is before the start time (e.g., overnight shifts)
	if (startDate !== endDate) {
		hoursDiff += 24; // 24-hour day
	}

	return hoursDiff;
}

// Checks if the time is within the lunch time range
export function isWithinLunchTimeRange(startTime: string, endTime: string): boolean {
	const lunchStart = '11:00';
	const lunchEnd = '13:00';

	// Check if the startTime or endTime is within the lunch time range
	const startWithinLunch = startTime <= lunchEnd && startTime >= lunchStart;
	const endWithinLunch = endTime >= lunchStart && endTime <= lunchEnd;

	// Check if the range overlaps with the lunch time range
	const rangeCoversLunch = startTime <= lunchStart && endTime >= lunchEnd;

	return startWithinLunch || endWithinLunch || rangeCoversLunch;
}

export const addMinutesToTime = (timeStr: string, minutesToAdd: number) => {
	if (typeof timeStr === 'undefined') return;
	// Split the time string into hours and minutes
	const [hours, minutes] = timeStr.split(':').map(Number);

	// Create a new Date object with the current date
	const date = new Date();
	date.setHours(hours);
	date.setMinutes(minutes);

	// Add the specified number of minutes
	date.setMinutes(date.getMinutes() + minutesToAdd);

	// Extract the new hours and minutes
	const newHours = date.getHours();
	const newMinutes = date.getMinutes();

	// Format the new time as 'HH :MM'
	const formattedHours = String(newHours).padStart(2, '0');
	const formattedMinutes = String(newMinutes).padStart(2, '0');

	return `${formattedHours}:${formattedMinutes}`;
};

interface DestructuredEntries {
	jobNo: string | null;
	jobTaskNo: string;
	hours: number;
	onCall: boolean;
}

interface DestructuredAddonLines {
	jobNo: string | null;
	jobTaskNo: string;
	hours: number;
	onCall: boolean;
	paymentSupplementCode: string | null;
}

export const countOnCallHours = (entries: DestructuredEntries[], addonLines: DestructuredAddonLines[], jobTaskNo: string, onCallHourDiff: number): {hours: number, overtimeHours: number} => {
	const hours = entries.reduce((acc, curr) => {
		if (curr.jobTaskNo === jobTaskNo && curr.onCall) {
			const difference = onCallHourDiff - curr.hours;
			return acc + difference;
		}

		return acc;
	}, 0);

	// '2100', Overtid 1
	// '2101', Overtid 2
	// '2105', Overtid (INTERN)
	// '8599', Overtid (INTERN)
	const overTimeCodes = ['2100', '2101', '2105', '8599'];

	const addonLineHours = addonLines.reduce((acc, curr) => {
		if (curr.jobTaskNo === jobTaskNo && curr.onCall && overTimeCodes.includes(curr.paymentSupplementCode ?? '')) {
			const difference = onCallHourDiff - curr.hours;
			return acc + difference;
		}

		return acc;
	}, 0);


	return {
		hours: hours,
		overtimeHours: addonLineHours,
	};
};